@import url("~leaflet/dist/leaflet.css");

:root{
  --main-text-color: black;

  --Sonder-Border-Color:rgb(0, 191, 80);
  --Sonder-Color: #fbfffc;

  --H-Color:#fdfeff;
  --H-Border-Color:rgb(142, 202, 255);

  --H1-Color:#eaf9ff;
  --H1-Border-Color:rgb(0, 204, 255);

  --H2-Color:#e7ecff;
  --H2-Border-Color:rgb(61, 132, 255);

  --H3-Color:#e2e2ff;
  --H3-Border-Color:rgb(17, 3, 208);

  --B-Color:#fffeec;
  --B-Border-Color:rgb(252, 255, 164);

  --B1-Color:#fffee9;
  --B1-Border-Color:#f2de00;

  --B2-Color:#fff4e5;
  --B2-Border-Color:#ff9100;

  --B3-Color:#ffe5e5;
  --B3-Border-Color:#d10000;

  --ABC-Color:#f4e9ff;
  --ABC-Border-Color:#ae5cff;

  --ABC1-Color:#e7d0ff;
  --ABC1-Border-Color:#8b17ff;

  --ABC2-Color:#d6acff;
  --ABC2-Border-Color:#5900b2;


  --reportInfo-Color:#fffcef;
  --reportInfo-Border-Color:#ffea00;

  --reportNews-Color:#fffef1;
  --reportNews-Border-Color:#edca03;

  --reportVeranstaltung-Color:#fff7f0;
  --reportVeranstaltung-Border-Color:#de7200;

  --reportJF-Color:#fcffed;
  --reportJF-Border-Color:#4f8b00;

  --reportDienst-Color:#fffcef;
  --reportDienst-Border-Color:#78705d;

  --reportJV-Color:#f7f0ff;
  --reportJV-Border-Color:#5900b2;
}

/* Timeline.css */
.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.timeline {
  position: relative;
  list-style: none;
  padding: 0;
}

.timeline-item {
  display: flex;
  align-items: center; /* Vertically center the content */
  position: relative;
  margin: 25px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #b71e10, #d9b833);
  color: #fff;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.timeline-item:hover {
  transform: scale(1.02);
}

.timeline-year {
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.171);
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  margin-right: 20px;
  animation: pulse 2s infinite alternate;
}

.timeline-content {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
}

.timeline-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.timeline-image {
  max-width: 100%; /* Ensure the image doesn't exceed its container width */
  height: auto; /* Automatically adjust height to maintain aspect ratio */
  max-height: 200px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.timeline-image:hover {
  transform: scale(1.05);
}

/* Animation for timeline year */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .timeline-item {
    flex-direction: column;
  }

  .timeline-year {
    margin-right: 0;
    margin-bottom: 10px;
  }
}




@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.side-toast-container {
  position: fixed;
  z-index: 10;
  top: 100px;
  right: -50px; /* Initially off-screen */
  transition: right 2s ease-in-out;
}

.bell-button {
  background: none;
  border: none;
  padding: 0;
  font-size: 24px;
  cursor: pointer;
  color: #f39200; /* Customize icon color */
}

/* Continuous ringing animation */
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.clicky:hover{
  font-size: 14px;
  font-weight: bold;
}
.poppi{
 
  font-size: 24px;
  box-shadow: 0 0 30px #f3920067;
  border-radius: 14px;
  background-color: #f392002c; /* Customize background color */
  color: #0e0505; /* Customize text color */
}
/* Apply the ringing animation */
.bell-button.ring {
  animation: ring 1.5s infinite;
}

/* Slide in animation */
.side-toast-container.bell-loaded {
  right: 20px; /* Slide in from the right */
}
.side-toast {
  animation: slideInFromRight 0.5s ease-in-out;
  width: 300px;
  background-color: #ffd900 !important; /* Customize background color */
  color: #ab2f2f; /* Customize text color */
}
.gradient-custom {

  /* fallback for old browsers */
  background: #bb1e10;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(187,30,16,0.01), rgba(221,187,19,0.01));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(187, 30, 16, 0.01), rgba(221,187,19,0.01))
}

.carousel-control-next, .carousel-control-prev{
  width: 5% !important;
}
.cradcaro{
  display: flex; 
  justify-content: center;
  
}
a:-webkit-any-link {
  color: black;
  cursor: pointer;
  text-decoration: inherit;
}
.margin-top {
    margin-top : 20px;
}
@font-face {
    font-family: "AltDeutsch";   /*Can be any text*/
    src: local("Canterbury"),
      url("./myFonts/Canterbury.ttf") format("truetype");
  }
 
  .cookie-banner {
    position: fixed !important;
  z-index: 1000 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);   
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .modal-content {
  position: relative;
  background-color: #fff;
  width: 400px;
  border-radius: 10px;  
  padding: 20px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-header { 
  display: flex;
  justify-content: space-between;
    align-items: center;
  margin-bottom: 20px;
  }
  
  .modal-header h2 {
  font-size: 24px;
  margin: 0;
  }
  
  .modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #999;
  cursor: pointer;
  }
  
  .modal-body {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  }
  
  .modal-buttons {
  display: flex;
  justify-content: flex-end;
  }
  
  .modal-buttons button {
    font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 10px;
    cursor: pointer;
  }
  
  .modal-buttons button.primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  }
  
  .modal-buttons button.secondary {
  background-color: #fff;
  color: #007bff; 
  border: 1px solid #007bff;
  }

.altDeutschFont{
  font-family: "AltDeutsch";
}

  .social-media {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    z-index: 1;
  }
  .socialmediaFooter {
    display: flex;
    justify-content: flex-end;
    z-index: 1;
  }
  .social-media a {
    display: inline-block;
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.543);
    transition: all 0.3s ease-in-out;
  }
  .cardFokus{
    transition: all 0.3s ease-in-out;
  }
  .cardFokus:hover{
    transform: scale(1.3);
  }
  .social-media a:hover {
    color: #fff;
    transform: scale(1.1);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .social-media a:first-child {
    margin-left: 0;
  }
  
  .social-media a:hover .facebook {
    color: #1877f2;
    box-shadow: 0 0 30px #1877f2;
  }
  
  .social-media a:hover .twitter {
    background-color: rgba(29, 156, 240, 0.347);
    color: rgb(29, 155, 240);
    box-shadow: 0 0 30px rgb(29, 155, 240);
  }
  
  .social-media a:hover .instagram {
    color: #e1306c;
    box-shadow: 0 0 30px #740b1d;
  }
  .GeimeindeLogo:hover{
    background-color: #740b1d;
    box-shadow: 0 0 30px #740b1d;
  }

  .altdeutsch{
    font-family: "AltDeutsch";
    text-shadow: -3px 0 #490c06, 0 3px #490c06, 2px 0 #490c06, 0 -2px #490c06;
    text-decoration: none;
    letter-spacing: 1px;
    color: white !important;
    text-decoration: none;
    font-size: 200%;
  }

  .altdeutsch:hover{
    color: white;
    text-shadow: -3px 0 #882016, 0 3px #882016, 2px 0 #882016, 0 -2px #882016;
    text-decoration: none;
    font-size: 200%;
  }
  .cardtext{
    color: var(--main-text-color); 
    line-height: 1.25;
    text-decoration: solid;
  }

  .overlayCaro::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.851), rgba(0, 0, 0, 0));
    z-index: 1;
  }
  .overlayAfter::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: -6%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.919), rgba(0, 0, 0, 0));
    z-index: 0;
  }
  .headpill{
    background-color: #ddc8c677; 
    margin-top: 3px !important; 
  }
  .headpill:hover{
    background-color: #ddc8c6; 
  }
  .bottompill{
    background-color: #ddc8c677 !important; 
    border: solid rgb(111, 111, 111) 0.1px !important;
    margin: 0.5px !important; 
  }
  .bottompill:hover{
    background-color: #ddc8c6 !important;
    color: #120101 !important; 
    text-decoration: solid !important;
   
  }

 .textBanner{
  text-shadow: -1px 0 #490c06, 0 1px #490c06, 1px 0 #490c06, 0 -1px #490c06;
 }

 .mapHeightLeaflet{
  height: 400px;
  max-height: 400px !important;
}
.missionCard{
  min-width: 200px;
  max-width: 50%;
  max-width: 250px !important; 
  height: 80%; 
  max-height: 400px !important;
  margin: 5px !important; 
  border-radius: 15px !important;
  position: relative;
  font-size: 90%;
  display: grid !important;  
  grid-template-columns: 0.1fr 0.8fr 3fr 0.8fr 3.1fr 0.1fr; 
  grid-template-rows: 0.5fr 1.5fr 2.2fr 1fr 1fr 1fr 1fr 1.5fr 0.1fr; 
  gap: 1% 1%; 
  grid-template-areas: 
    ". top1 . . top2 ."
    ". img img img img ."
    ". img img img img ."
    ". title title title title ."
    ". protIcon prot prot prot ."
    ". calIcon cal cloIcon clo ."
    ". locIcon loc loc loc ."
    ". instIcon inst inst inst ."
    ". . . . . ."; 
}
.missionCard:hover{
  position: relative!important;
  z-index: 2 !important;
  transform: scale(1.25);
  transition: all 0.3s ease-in-out; 
}
.Sonstiges{
  border: solid var(--Sonder-Border-Color) 4px !important;
  background-color: var(--Sonder-Color) !important;
}
.Sonstiges:hover{
  box-shadow: 0 0 20px var(--Sonder-Border-Color);
}
.H{
  border: solid var(--H-Border-Color) 4px !important;
  background-color: var(--H-Color) !important;
}
.H:hover{
  box-shadow: 0 0 20px var(--H1-Border-Color);
}
.H1{
  border: solid var(--H1-Border-Color) 4px !important;
  background-color: var(--H1-Color) !important;
}
.H1:hover{
  box-shadow: 0 0 20px var(--H1-Border-Color);
}
.H2{
  border: solid var(--H2-Border-Color) 4px !important;
  background-color: var(--H2-Color) !important;
}
.H2:hover{
  box-shadow: 0 0 20px var(--H2-Border-Color);
}
.H3{
  border: solid var(--H3-Border-Color) 4px !important;
  background-color: var(--H3-Color) !important;
}
.H3:hover{
  box-shadow: 0 0 20px var(--H3-Border-Color);
}
.B{
  border: solid var(--B-Border-Color) 4px !important;
  background-color: var(--B-Color) !important;
}
.B:hover{
  box-shadow: 0 0 20px var(--B-Border-Color);
}
.B1{
  border: solid var(--B1-Border-Color) 4px !important;
  background-color: var(--B1-Color) !important;
}
.B1:hover{
  box-shadow: 0 0 20px var(--B1-Border-Color);
}
.B2{
  border: solid var(--B2-Border-Color) 4px !important;
  background-color: var(--B2-Color) !important;
}
.B2:hover{
  box-shadow: 0 0 20px var(--B2-Border-Color);
}
.B3{
  border: solid var(--B3-Border-Color) 4px !important;
  background-color: var(--B3-Color) !important;
}
.B3:hover{
  box-shadow: 0 0 20px var(--B3-Border-Color);
}
.ABC{
  border: solid var(--ABC-Border-Color) 4px !important;
  background-color: var(--ABC-Color) !important;
}
.ABC:hover{
  box-shadow: 0 0 20px var(--ABC-Border-Color);
}
.ABC1{
  border: solid var(--ABC1-Border-Color) 4px !important;
  background-color: var(--ABC1-Color) !important;
}
.ABC1:hover{
  box-shadow: 0 0 20px var(--ABC1-Border-Color);
}
.ABC2{
  border: solid var(--ABC2-Border-Color) 4px !important;
  background-color: var(--ABC2-Color) !important;
}
.ABC2:hover{
  box-shadow: 0 0 20px var(--ABC2-Border-Color);
}


.a:hover{
  color: black !important;
}
.cal { grid-area: cal;   place-items: center; 
  align-items: center;}
.instIcon { grid-area: instIcon;   place-items: center; margin-left:2%;
  align-items: center; }
.locIcon { grid-area: locIcon;   place-items: center; margin-left:2%;
  align-items: center; }
.calIcon { grid-area: calIcon;   place-items: center; margin-left:2%;
  align-items: center;}
.protIcon { grid-area: protIcon;  place-items: center;margin-left:2%;
  align-items: center;}
.cloIcon { grid-area: cloIcon;   place-items: center;margin-left:2%;
  align-items: center;}
.clo { grid-area: clo;   place-items: center;
  align-items: center;}
.loc { grid-area: loc;   place-items: center;
  align-items: center;}
.inst { grid-area: inst;   place-items: center;
  align-items: center;}
.prot { grid-area: prot;   place-items: center;
  align-items: center;}
.title { grid-area: title; text-align: center; font-weight: bold;
  font-size: 17px;}
.img { grid-area: img; min-height: 50px; 
 }
  .card-img{ overflow: clip;
    animation-fill-mode: both; max-width: 100%;   vertical-align: middle; aspect-ratio: auto 600 / 400;  animation-duration: 1.5s;   animation-name: fadeInLeftBig; overflow-clip-margin: content-box;
  }
.top1 { grid-area: top1;place-items: center;}
.top2 { grid-area: top2; text-align: right; }

.carousel-item:hover {
  position: relative;
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
}

.containerMP {
  position: relative;
  color: #120101;
  display: grid !important; 
  grid-template-columns: 1fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "areaMP"
    "mapMP"
    "areaMP2"; 
}

.areaMP {
  justify-items: center;
  display: grid; 
  grid-auto-flow: column; 
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    ". . ."; 
  grid-area: areaMP; 
}
.areaMP2 {
  grid-auto-flow: column; 
  grid-auto-columns: 1fr;
  justify-items: center;
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    ". . ."; 
  grid-area: areaMP2; 
}

.mission-icon {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1700px) and (min-width: 900px){
  .containerReport {
    grid-template-columns: 0.7fr 2fr 0.2fr !important; 
    grid-template-rows: 0.6fr 1.6fr 1fr 0.8fr;  
  }
  .iconCal { grid-area: iconCal; font-size: 115% !important;}
  .titleCal { grid-area: titleCal; font-weight: bold; font-size: 115% !important; }
  .textcalBegin { grid-area: textCalBegin; font-size: 70% !important; }
  .DateCal { grid-area: DateCal; font-size: 70% !important; }
  .InstiCal { grid-area: InstiCal; font-size: 70% !important;}
  .titleReport { 
    font-size: 130% !important;}
  .catReport { font-size: 100% !important; }
  .dateReport { font-size: 80% !important;}
  .instiReport{ font-size: 80% !important;}
  .textBegin{
    font-size: 80% !important;
  }
  .MainParty { 
    font-size: 68% !important;
  
  }
  .MainParty2 { 
    font-size: 90% !important;
  
  }
 .WelText{
  max-height: 300px !important;
 }
  .PicWel1{
    max-height: 300px !important;
  }
  .PicWel2{
    max-height: 300px !important;
  }
  .carousel{
    font-size:60%;
  }
  .areaMP{
    font-size:60%;
  }
  .areaMP2{
    font-size:60%;
  }

  .missionCard{
    min-width: 140px !important;
    max-width: 210px !important; 
    max-height: 300px !important;
    font-size: 115%;
    
  }
  .mapHeightLeaflet{
    max-height: 300px !important;
  }
  
  .title{
    font-size: 125%;
  }
}
@media only screen and (max-width: 900px) and (min-width: 560px){
  .MainParty2 { 
    font-size: 80% !important;
  
  }
  .containerReport {
    grid-template-columns: 0.7fr 2fr 0.2fr !important; 
    grid-template-rows: 0.6fr 1.6fr 1fr 0.8fr;  
  }
  .iconCal { grid-area: iconCal; font-size: 100% !important;}
  .titleCal { grid-area: titleCal; font-weight: bold; font-size: 100% !important; }
  .DateCal { grid-area: DateCal; font-size: 50% !important; }
  .InstiCal { grid-area: InstiCal; font-size: 50% !important;}
  .textcalBegin { grid-area: textCalBegin; font-size: 50% !important; }

  .navbar{
    background-color: #b71e10 !important;
  }
  .carousel{
    font-size:60%;
  }
  .areaMP{
    font-size:60%;
  }
  .areaMP2{
    font-size:60%;
  }
  .missionCard{
    min-width: 130px !important;
    max-width: 180px !important; 
    max-height: 265px !important;
    font-size: 110%;
  }
  .mapHeightLeaflet{
    max-height: 265px !important;
  }
  .title{
    font-size: 115%;
  }
  .containerReport{
    max-height: 150px !important;
  }
  .textBegin { font-size: 70% !important; }
.titleReport { 
  font-size: 80% !important;}
.catReport { font-size: 90% !important; }
.dateReport { font-size: 70% !important;}
.instiReport{ font-size: 70% !important;}
.containerMainPage {
  display: grid; 
  grid-template-columns: 1fr !important; 
  grid-template-rows: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr !important; 
  gap: 0px 0px; 
  grid-template-areas: 
    "ConUni" 
    "missionMainCon" 
    "mapMainCon"
    "reportMainCon"
    "calenderMainCon"
    "SideArea" !important; 
}
.SideArea { 
  display: grid; 
  grid-template-columns: 1fr 1fr !important; 
  grid-template-rows: 0.01fr 1fr 0.5fr !important; 
  gap: 0px 0px; 
  grid-template-areas:
    "titleDias titleDias" 
    "diaMain2 diaMain3"
    "diaMain1 spaceDia" !important; 
  grid-area: SideArea; 
}

.pdf-container{
  margin-top: 3% !important;
}
.MainParty { grid-area: MainParty; 
  font-size: 80% !important;
display: grid; 
  grid-template-columns: 1fr 1fr !important; 
  grid-template-rows: 0.7fr 1fr !important; 
  gap: 0px 0px; 
  grid-template-areas: 
    "WelText WelText"
    "PicWel1 PicWel2" !important; }
    .titleMain2 {font-size: 90% ; padding-left: 3% !important;}
    .titleMain {font-size: 90% ; padding-left: 3% !important;}
    
}
@media only screen and (max-width: 580px) and (min-width: 350px){
  .side-toast-container {top: 60px;}
  .navLinkos{
    padding-top: 3% !important;
  }
  .navLinko{
    padding-left: 3% !important;
  }
  .navText{
    font-size: 80% !important;
  }
  .MainParty2 { 
    font-size: 80% !important;
  
  }
  .containerReport {
    grid-template-columns: 0.9fr 1.9fr 0.2fr !important; 
    grid-template-rows: 0.6fr 1.6fr 1fr 0.8fr;  
  }
  .iconCal { grid-area: iconCal; font-size: 110% !important;}
  .titleCal { grid-area: titleCal; font-weight: bold; font-size: 110% !important; }
  .DateCal { grid-area: DateCal; font-size: 60% !important; }
  .InstiCal { grid-area: InstiCal; font-size: 60% !important;}
  .textcalBegin { grid-area: textCalBegin; font-size: 60% !important; }

  .PicWel1{
    max-height: 270px !important;
  }
  .PicWel2{
    max-height: 270px !important;
  }
  .ConUniPart{ 
    display: grid; 
    grid-template-columns: 1fr !important; 
    grid-template-rows: 0.1fr 1fr !important; 
    grid-template-areas: 
      "textPartUni"  
      "PicPartUni" !important; 
    grid-area: ConUniPart;
  }
  .navbar{
    background-color: #b71e10 !important;
  }
  .pdf-container{
    margin-top: 5% !important;
  }
  .containerMainPage {
    display: grid; 
    grid-template-columns: 1fr !important; 
    grid-template-rows: 0.4fr 0.25fr 0.6fr 0.7fr 1.1fr 0.7fr !important; 
    gap: 0px 0px; 
    grid-template-areas: 
      "ConUni" 
      "missionMainCon" 
      "mapMainCon"
      "reportMainCon"
      "calenderMainCon"
      "SideArea" !important; 
  }
  .SideArea { 
    display: grid; 
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 0.01fr 1fr 0.5fr !important; 
    gap: 0px 0px; 
    grid-template-areas:
      "titleDias titleDias" 
      "diaMain2 diaMain3"
      "diaMain1 spaceDia" !important; 
    grid-area: SideArea; 
  }
  .MainParty { grid-area: MainParty; 
    font-size: 80% !important;
  display: grid; 
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 1fr 0.5fr !important; 
    gap: 0px 0px; 
    grid-template-areas: 
      "WelText WelText"
      "PicWel1 PicWel2" !important; }
      .titleMain2 {font-size: 80% !important; padding-left: 3% !important;}
      .titleMain {font-size: 80% !important; padding-left: 3% !important;}

  .containerCalPart {  display: grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr;
    gap: 5px 5px;
    grid-auto-flow: row;
    grid-template-areas:
      "calendarPart" 
      "CalendarItemsPart" !important;
  }

  .containerReport{
    max-height: 150px !important;
    grid-template-columns: 1fr 1.8fr 0.2fr !important; 
  }
  .textBegin { font-size: 45% !important; }
.titleReport { 
  font-size: 75% !important;}
.catReport { font-size: 80% !important; }
.dateReport { font-size: 50% !important;}
.instiReport{ font-size: 50% !important;}


  .social-media {
  margin: 5px;
  }
  .missionCard{
    margin: 3px !important; 
    min-width: 120px !important;
    max-width: 130px !important; 
    max-height: 200px !important;
    font-size: 107%;
  }
  .mapHeightLeaflet{
    max-height: 200px !important;
  }
  .carousel{
    font-size:40% !important;
  }
  .areaMP {  
    font-size:40% !important;
   
  }
  .areaMP2 {
    font-size:40% !important;
  }
  .title{
    font-size: 125%;
  }

  .containermdet {
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 0.15fr 1.4fr 1.3fr 1.4fr !important;
    gap: 5px 5px;
    grid-template-areas:
      "titledetails"
      "detailsinfo"
      "reporty"
      "mapdetail"
      "imagesdet" !important;
  }
  .detailsinfo {
    font-size: 60%;  
    background-color: #d7d7d7;
    border: 3px solid black;
    display: grid; 
    place-items: start;
    align-items: center;
    grid-template-columns: 0.5fr 0.5fr 1fr !important;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr !important;
    gap: 1px 1px;
    grid-auto-flow: row;
    grid-template-areas:
      "ipr pr picdetails"
      "ical cal picdetails"
      "icl cl picdetails"
      "icat cat picdetails"
      "iloc loc picdetails"
      "iem em picdetails"
      "iinsti inst picdetails"
      "linksIc linksMi picdetails" !important;
    grid-area: detailsinfo;
  }
  .form-control:disabled{
    font-size: 75% !important;
  }
  .titledetails{
    font-size: 70%;
  }


  .containerDetailsRepo {
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 0.1fr 1.2fr 0.1fr !important; 
    grid-template-areas: 
      "titleRepo titleRepo"
      "textAreaRepo textAreaRepo"
      "ImageConRepo ImageConRepo"; 
  }
  .titleRepo {
    background-color: #8ea1b6;
    border: 2px solid black;
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px; 
    text-align: center; font-weight: bold; font-size: 80% !important;
    display: grid;
    place-items: center;
    grid-template-columns: 0.1fr 1.8fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "IconTitle titleTextRepo"; 
    grid-area: titleRepo; 
  }
  .IconTitle { grid-area: IconTitle; }
  .titleTextRepo { grid-area: titleTextRepo; }
  .textAreaRepo {
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 0.1fr 0.8fr 1fr 0.1fr !important; 
    gap: 3px 3px; 
    grid-template-areas: 
      "InfoRepo InstiRepo "
      "ImagaRepo ImagaRepo "
      "textRepo textRepo"
      "LinksRepo LinksRepo" !important;  
    grid-area: textAreaRepo; 
  }
  .InfoRepo { grid-area: InfoRepo; font-weight: lighter; font-size: 80% !important }
  .InstiRepo { grid-area: InstiRepo; margin: 1%;font-size: 80% !important }
  .ImagaRepo { grid-area: ImagaRepo;margin: 2%; place-items: center;
    align-items: center; }
  .LinksRepo { grid-area: LinksRepo; text-align: center;font-weight: lighter; margin: 1%; font-size: 80% !important}
 
}
@media only screen and (max-width: 350px) {
  .side-toast-container {top: 60px;}
  .navLinkos{
    padding-top: 3% !important;
  }
  .navLinko{
    padding-left: 3% !important;
  }
  .navText{
    font-size: 70% !important;
  }
  .MainParty2 { 
    font-size: 80% !important;
  
  }
  .containerReport {
    grid-template-columns: 0.9fr 1.9fr 0.2fr !important; 
    grid-template-rows: 0.6fr 1.6fr 1fr 0.8fr;  
  }
  .iconCal { grid-area: iconCal; font-size: 100% !important;}
  .titleCal { grid-area: titleCal; font-weight: bold; font-size: 100% !important; }
  .DateCal { grid-area: DateCal; font-size: 50% !important; }
  .InstiCal { grid-area: InstiCal; font-size: 50% !important;}
  .textcalBegin { grid-area: textCalBegin; font-size: 50% !important; }

  .PicWel1{
    max-height: 270px !important;
  }
  .PicWel2{
    max-height: 270px !important;
  }
  .ConUniPart{ 
    display: grid; 
    grid-template-columns: 1fr !important; 
    grid-template-rows: 0.1fr 1fr !important; 
    grid-template-areas: 
      "textPartUni"  
      "PicPartUni" !important; 
    grid-area: ConUniPart;
  }
  .navbar{
    background-color: #b71e10 !important;
  }
  .pdf-container{
    margin-top: 3.5% !important;
  }
  .containerMainPage {
    display: grid; 
    grid-template-columns: 1fr !important; 
    grid-template-rows: 0.4fr 0.25fr 0.6fr 0.7fr 1.1fr 0.7fr !important; 
    gap: 0px 0px; 
    grid-template-areas: 
      "ConUni" 
      "missionMainCon" 
      "mapMainCon"
      "reportMainCon"
      "calenderMainCon"
      "SideArea" !important; 
  }
  .SideArea { 
    display: grid; 
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 0.01fr 1fr 0.5fr !important; 
    gap: 0px 0px; 
    grid-template-areas:
      "titleDias titleDias" 
      "diaMain2 diaMain3"
      "diaMain1 spaceDia" !important; 
    grid-area: SideArea; 
  }
  .MainParty { grid-area: MainParty; 
    font-size: 70% !important;
  display: grid; 
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 1fr 0.5fr !important; 
    gap: 0px 0px; 
    grid-template-areas: 
      "WelText WelText"
      "PicWel1 PicWel2" !important; }
      .titleMain2 {font-size: 70% !important; padding-left: 2.5% !important;}
      .titleMain {font-size: 70% !important; padding-left: 2.5% !important;}

  .containerCalPart {  display: grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr;
    gap: 4px 4px;
    grid-auto-flow: row;
    grid-template-areas:
      "calendarPart" 
      "CalendarItemsPart" !important;
  }

  .containerReport{
    max-height: 150px !important;
    grid-template-columns: 1fr 1.8fr 0.2fr !important; 
  }
  .textBegin { font-size: 35% !important; }
.titleReport { 
  font-size: 65% !important;}
.catReport { font-size: 70% !important; }
.dateReport { font-size: 40% !important;}
.instiReport{ font-size: 40% !important;}


  .social-media {
  margin: 4px;
  }
  .missionCard{
    margin: 3px !important; 
    min-width: 120px !important;
    max-width: 130px !important; 
    max-height: 200px !important;
    font-size: 95%;
  }
  .mapHeightLeaflet{
    max-height: 200px !important;
  }
  .carousel{
    font-size:30%;
  }
  .areaMP {  
    font-size:30%;
   
  }
  .areaMP2 {
    font-size:30%;
  }
  .title{
    font-size: 105%;
  }

  .containermdet {
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 0.15fr 1.4fr 1.3fr 1.4fr !important;
    gap: 4px 4px;
    grid-template-areas:
      "titledetails"
      "detailsinfo"
      "reporty"
      "mapdetail"
      "imagesdet" !important;
  }
  .detailsinfo {
    font-size: 50%;  
    background-color: #d7d7d7;
    border: 2px solid black;
    display: grid; 
    place-items: start;
    align-items: center;
    grid-template-columns: 0.5fr 0.5fr 1fr !important;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr !important;
    gap: 1px 1px;
    grid-auto-flow: row;
    grid-template-areas:
      "ipr pr picdetails"
      "ical cal picdetails"
      "icl cl picdetails"
      "icat cat picdetails"
      "iloc loc picdetails"
      "iem em picdetails"
      "iinsti inst picdetails"
      "linksIc linksMi picdetails" !important;
    grid-area: detailsinfo;
  }
  .form-control:disabled{
    font-size: 65% !important;
  }
  .titledetails{
    font-size: 60%;
  }


  .containerDetailsRepo {
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 0.1fr 1.2fr 0.1fr !important; 
    grid-template-areas: 
      "titleRepo titleRepo"
      "textAreaRepo textAreaRepo"
      "ImageConRepo ImageConRepo"; 
  }
  .titleRepo {
    background-color: #8ea1b6;
    border: 2px solid black;
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px; 
    text-align: center; font-weight: bold; font-size: 70% !important;
    display: grid;
    place-items: center;
    grid-template-columns: 0.1fr 1.8fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "IconTitle titleTextRepo"; 
    grid-area: titleRepo; 
  }
  .IconTitle { grid-area: IconTitle; }
  .titleTextRepo { grid-area: titleTextRepo; }
  .textAreaRepo {
    grid-template-columns: 1fr 1fr !important; 
    grid-template-rows: 0.1fr 0.8fr 1fr 0.1fr !important; 
    gap: 3px 3px; 
    grid-template-areas: 
      "InfoRepo InstiRepo "
      "ImagaRepo ImagaRepo "
      "textRepo textRepo"
      "LinksRepo LinksRepo" !important;  
    grid-area: textAreaRepo; 
  }
  .InfoRepo { grid-area: InfoRepo; font-weight: lighter; font-size: 70% !important }
  .InstiRepo { grid-area: InstiRepo; margin: 1%;font-size: 70% !important }
  .ImagaRepo { grid-area: ImagaRepo;margin: 2%; place-items: center;
    align-items: center; }
  .LinksRepo { grid-area: LinksRepo; text-align: center;font-weight: lighter; margin: 1%; font-size: 70% !important}
 
}


  .bannerImg{
    aspect-ratio: 3/1;
    object-fit: cover;
  }

 .imagestyle{
  height: 100%;
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
 }
 .imagestyle2{
  max-height: 100px;
  height: 100%;
  width: 100%;
 
  object-fit: contain;
 }

 .imgaebackgroundremove{
  mix-blend-mode: color-burn;
 }
  
  
 .custom-calendar {
  border: none;
}

.custom-tile {
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.custom-tile:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.react-calendar {
  padding: 1%;
  width: 100% !important;
  height: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  
}
 
.react-calendar__navigation button {
  color: #d4a713;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
 
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
 
/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #d4a713;
  border-radius: 6px;
}
.react-calendar__tile {
  flex: 1 ;
  justify-content: center;
} 
.react-calendar__tile--now {
  background: #d4a71333;
  border-radius: 6px;
  font-weight: bold;
  color: #d4a713;
}
 
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #d4a71333;
  border-radius: 6px;
  font-weight: bold;
  color: #d4a713;
}
 
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
 
.react-calendar__tile--active {
  background: #d4a713;
  border-radius: 6px;
  font-weight: bold;
  color: rgb(157, 133, 24) !important;
}
 
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #d4a713;
  color: rgb(157, 133, 24);
}
 
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
 
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #d4a713;
  border-radius: 0;
}
 
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #d4a713;
  color: white;
}
 
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #d4a713;
  color: white;
}


.container-calender {
  position: relative;
  height: 100%;
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "cal currentEntry calList"; 
}
.cal { grid-area: cal; }
.currentEntry { grid-area: currentEntry; border: 1px solid #ccc;
  padding: 20px;
  margin: 5px;}
.calList { grid-area: calList;
  border: 1px solid #ccc; 
  padding: 20px;
  margin: 5px; }

.appointment-tile {
  border: 2px solid #ccc;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}
.react-calendar__tile {
  border-radius: 15px !important;
}
.containermdet {
  margin: 1%;
  display: grid;
  grid-template-columns: 1fr 0.8fr 1.2fr;
  grid-template-rows: 0.15fr 1.4fr 1.3fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "titledetails titledetails titledetails"
    "detailsinfo detailsinfo detailsinfo"
    "reporty reporty mapdetail"
    "imagesdet imagesdet imagesdet";
}

.titledetails {
  background-color: #fdfaf9;
  border: 2px solid #490c06;
  border-top-left-radius: 10px;  
  border-top-right-radius: 10px; 

  display: grid;
  place-items: center;
  grid-template-columns: 0.2fr 2.6fr 0.2fr;
  grid-template-rows: 1fr;
  gap: 3px 3px;
  grid-auto-flow: row;
  grid-template-areas:
    "categorydetails mtitle emergedetails";
  grid-area: titledetails;
}

.categorydetails { grid-area: categorydetails; }

.mtitle { grid-area: mtitle; text-align: center; font-weight: bold; font-size: 150%; }

.emergedetails { grid-area: emergedetails; }



.detailsinfo {  
  background-color: #faf4f3;
  border: 3px solid #490c06;
  display: grid; 
  place-items: start;
  align-items: center;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.3fr 1.2fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 2px 2px;
  grid-auto-flow: row;
  grid-template-areas:
    "ipr pr iloc loc picdetails"
    "ical cal iem em picdetails"
    "icl cl iinsti inst picdetails"
    "icat cat linksIc linksMi picdetails";
  grid-area: detailsinfo;
}
.picdetails { grid-area: picdetails; margin: 2%; place-items: center;
  align-items: center;}
.ipr { margin-left: 5%; grid-area: ipr; font-weight: bold; text-align: start;}

.pr { grid-area: pr; }

.ical {margin-left: 5%; grid-area: ical; font-weight: bold;}

.cal { grid-area: cal; }

.icl {margin-left: 5%; grid-area: icl;font-weight: bold; }

.cl { grid-area: cl; }

.icat {margin-left: 5%; grid-area: icat;font-weight: bold; }

.cat { grid-area: cat; }

.iloc {margin-left: 5%; grid-area: iloc; font-weight: bold;}

.loc { grid-area: loc; }

.iem {margin-left: 5%; grid-area: iem; font-weight: bold;}

.em { grid-area: em; }

.iinsti {margin-left: 5%; grid-area: iinsti; font-weight: bold;}

.insti { grid-area: inst; place-items: top;}

.linksMi{
  grid-area: linksMi; place-items: top;
}
.linksIc{
  margin-left: 5%; grid-area: linksIc; font-weight: bold;
}

.mapdetail {   
  grid-area: mapdetail;
  border: 3px solid #490c06;
}

.reporty { grid-area: reporty; 
  background-color: #fdfaf9;
  border: 3px solid #490c06;}

.imagesdet {
  background-color: #faf4f3;
  border: 3px solid #490c06;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  grid-area: imagesdet; 
}

.form-control:disabled {
 background-color: #007afc00 !important;
 border-color: #007bff00 !important;
}
.reportInfo{
  border: solid var(--reportInfo-Border-Color) 4px !important;
  background-color: var(--reportInfo-Color) !important;
}
.reportInfo:hover{
  box-shadow: 0 0 20px var(--reportInfo-Border-Color);
}
.reportNews{
  border: solid var(--reportNews-Border-Color) 4px !important;
  background-color: var(--reportNews-Color) !important;
}
.reportNews:hover{
  box-shadow: 0 0 20px var(--reportNews-Border-Color);
}
.reportDienstbericht{
  border: solid var(--reportDienst-Border-Color) 4px !important;
  background-color: var(--reportDienst-Color) !important;
}
.reportDienstbericht:hover{
  box-shadow: 0 0 20px var(--reportDienst-Border-Color);
}
.reportJahreshauptversammlung{
  border: solid var(--reportJV-Border-Color) 4px !important;
  background-color: var(--reportJV-Color) !important;
}
.reportJahreshauptversammlung:hover{
  box-shadow: 0 0 20px var(--reportJV-Border-Color);
}
.reportVeranstaltung{
  border: solid var(--reportVeranstaltung-Border-Color) 4px !important;
  background-color: var(--reportVeranstaltung-Color) !important;
}
.reportVeranstaltung:hover{
  box-shadow: 0 0 20px var(--reportVeranstaltung-Border-Color);
}
.containerReport {
  color: black;
  z-index: 0 !important;
  max-width: 100%;
  max-height: 200px;
  margin: 5px !important; 
  padding: 10px;
  border: black solid 2px;
  border-radius: 15px !important;
  display: grid; 
  grid-template-columns: 0.6fr 2.2fr 0.2fr; 
  grid-template-rows: 0.6fr 1.6fr 1fr 0.8fr; 
  gap: 6px 15px;
  grid-template-areas: 
    "ImageReport dateReport catReport"
    "ImageReport titleReport titleReport"
    "ImageReport textBegin textBegin"
    "ImageReport instiReport instiReport"; 
 
}
.ImageReport { grid-area: ImageReport; }
.instiReport { grid-area: instiReport; text-align: center;}
.textBegin { grid-area: textBegin; }
.titleReport { grid-area: titleReport; font-weight: bold;
  font-size: 30px;}
.catReport { grid-area: catReport; place-items: center; text-align: center;}
.dateReport { grid-area: dateReport; text-align: end;}
.ImgStyleReport{
  height: 100%;
  width: 100%;
  object-fit: cover !important;
  border: 1px solid black;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.buttonSwipeCon{
  z-index: 1;
  position: relative;

}


.controlBottom{
  border: none !important;
  box-shadow: none !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 1 !important;
}
.controlTop{
  border: none !important;
  box-shadow: none !important;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 1 !important;
}
.controlTop:hover{
 background-color: #ffffff6a !important;
}
.controlBottom:hover{
  background-color: #ffffff6a !important;
 }
 .reportslide:hover{
  position: relative;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
 }
 .containerReport:hover{
  position: relative!important;
  z-index: 1 !important;
  transform: scale(1.15);
  transition: all 0.3s ease-in-out; 
}
.containerReportPage:hover{
  transform: scale(1.03) !important;
}

.containerDetailsRepo {
  margin: 1%;
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 0.2fr 1.2fr 0.6fr; 
  gap: 5px 5px; 
  grid-template-areas: 
    "titleRepo titleRepo titleRepo"
    "textAreaRepo textAreaRepo textAreaRepo"
    "ImageConRepo ImageConRepo ImageConRepo"; 
}
.titleRepo {
  background-color: #faf4f3;
  border: 2px solid #490c06;
  border-top-left-radius: 10px;  
  border-top-right-radius: 10px; 
  text-align: center; font-weight: bold; font-size: 150%;
  display: grid;
  place-items: center;
  grid-template-columns: 0.1fr 1.8fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "IconTitle titleTextRepo"; 
  grid-area: titleRepo; 
}
.IconTitle { grid-area: IconTitle; }
.titleTextRepo { grid-area: titleTextRepo; }
.textAreaRepo {
  background-color: #fdfaf9;
  border: 3px solid #490c06;
  
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 0.1fr 1fr 0.1fr; 
  gap: 3px 3px; 
  grid-template-areas: 
    "InfoRepo InstiRepo ImagaRepo"
    "textRepo textRepo ImagaRepo"
    "textRepo textRepo LinksRepo";
   
  grid-area: textAreaRepo; 
}
.InfoRepo { grid-area: InfoRepo; margin: 1%; font-weight: lighter;
  justify-content: center; }
.InstiRepo { grid-area: InstiRepo; margin: 1%}
.ImagaRepo { grid-area: ImagaRepo;margin: 2%; place-items: center;
  align-items: center; }
.LinksRepo { grid-area: LinksRepo; text-align: center;font-weight: lighter; margin: 1%; }
.textRepo { grid-area: textRepo; }
.ImageConRepo { grid-area: ImageConRepo;background-color: #faf4f3;
  border: 3px solid #490c06;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; }

  .containerCalItem:hover {
    transform: scale(1.15) !important;
  }
  .caroPicFokus{
    transform: scale(1.15) !important;
  }
  .containerCalItem {
    color: black;
  z-index: 0 !important;
  max-width: 100%;
  max-height: 200px;
  margin: 5px !important; 
  padding: 10px;
  border: #490c06 solid 2px;
  border-radius: 15px !important;
    display: grid; 
    grid-template-columns: 0.1fr 1.6fr 1fr; 
    grid-template-rows: 0.5fr 0.2fr 0.2fr 0.2fr; 
    gap: 6px 10px; 
    grid-template-areas: 
      "iconCal titleCal titleCal"
      "textcalBegin textcalBegin textcalBegin"
      "DateCal DateCal DateCal"
      "InstiCal InstiCal InstiCal"; 
  }
  .textcalBegin{ grid-area: textcalBegin; text-align: center; font-size: 90%;}
  .iconCal { grid-area: iconCal; font-size: 150%;}
  .titleCal { grid-area: titleCal; font-weight: bold; font-size: 150%; text-align: center;}
  .DateCal { grid-area: DateCal; text-align: center; font-weight: lighter; font-size: 80%; }
  .InstiCal { grid-area: InstiCal; text-align: center; }

  .calEreignis{
    border: solid var(--reportInfo-Border-Color) 4px !important;
    background-color: var(--reportInfo-Color) !important;
}
  .calEreignis:hover{
    box-shadow: 0 0 20px var(--reportInfo-Border-Color);
  }
  .calVeranstaltung{
    border: solid var(--reportInfo-Border-Color) 4px !important;
    background-color: var(--reportInfo-Color) !important;
  }
  .calVeranstaltung:hover{
    box-shadow: 0 0 20px var(--reportInfo-Border-Color);
  }
 .calInfo{
    border: solid var(--reportInfo-Border-Color) 4px !important;
    background-color: var(--reportInfo-Color) !important;
  }
  .calInfo:hover{
    box-shadow: 0 0 20px var(--reportInfo-Border-Color);
  }
  .calJugendfeuerwehr{
    border: solid var(--reportJF-Border-Color) 4px !important;
    background-color: var(--reportJF-Color) !important;
  }
  .calJugendfeuerwehr:hover{
    box-shadow: 0 0 20px var(--reportJF-Border-Color);
  }
  .calJahreshauptversammlung{
    border: solid var(--reportJV-Border-Color) 4px !important;
    background-color: var(--reportJV-Color) !important;
  }
  .calJahreshauptversammlung:hover{
    box-shadow: 0 0 20px var(--reportJV-Border-Color);
  }
  .calDienst{
    border: solid var(--reportDienst-Border-Color) 4px !important;
    background-color: var(--reportDienst-Color) !important;
  }
  .calDienst:hover{
    box-shadow: 0 0 20px var(--reportDienst-Border-Color);
  }

  .containerCalPart {  display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 5px;
    grid-auto-flow: row;
    grid-template-areas:
      "calendarPart CalendarItemsPart";
  }
  
  .calendarPart { grid-area: calendarPart; }
  
  .CalendarItemsPart { grid-area: CalendarItemsPart; }
  .calendarPageItem:hover{
    transform: scale(1.03) !important;
  }

  
  .containerMainPage {
    z-index: 1 !important;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 0.75fr; 
    grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "ConUni ConUni ConUni SideArea" 
      "missionMainCon missionMainCon missionMainCon SideArea" 
      "mapMainCon mapMainCon mapMainCon SideArea"
      "reportMainCon reportMainCon reportMainCon SideArea"
      "calenderMainCon calenderMainCon calenderMainCon SideArea"; 
  }

  .missionMainCon{
    grid-area: missionMainCon; 
  }
  .mapMainCon{
    grid-area: mapMainCon; 
  }
  .reportMainCon{
    grid-area: reportMainCon; 
  }
  .calenderMainCon{
    grid-area: calenderMainCon; 
  }

  .SideArea { 
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 0.01fr 0.5fr 1fr 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas:
      "titleDias" 
      "diaMain1"
      "diaMain2"
      "diaMain3"
      "spaceDia"; 
    grid-area: SideArea; 
    border: solid #490c06 1px;
    border-radius: 15px;
    background-color: #fdfaf9;
    margin: 1%;
    margin-right: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .titleDias { grid-area: titleDias; border-bottom: solid #490c06 1px; color: white;
    border-top-left-radius: 15px;border-top-right-radius: 15px; font-weight: bold; font-size: 110%;padding:1%;
    background-color: #b71e10;}
    .diaMain1 { grid-area: diaMain1; margin: 1%;}
    .diaMain2 { grid-area: diaMain2; margin: 1%;}
    .diaMain3 { grid-area: diaMain3; margin: 1%;}
    .spaceDia { grid-area: spaceDia; margin: 1%;}
.navbar{
  padding-right: 1% !important;
  z-index: 3 !important;
  background-color: #b71e10b9;
}
.navbar:hover{
  background-color: #b71e10;
}
.navbar-toggler{
  border: 1px solid rgba(222, 194, 194, 0.823)  !important;
}
.navbar-toggler:hover{
  border: 2px solid rgb(222, 194, 194)  !important;
}

.nav-link{
color: rgba(255, 255, 255, 0.931) !important;
}
.nav-link:hover{
  background-color: rgba(179, 41, 41, 0.255);
  color: rgb(255, 255, 255) !important;
  box-shadow: 0 0 2px white;
}

.ConUni { 
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 0.01fr 0.8fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "titleMain titleMain titleMain" 
    "MainParty MainParty MainParty"; 
  margin: 1%;
  grid-area: ConUni;
}
  .titleMain { grid-area: titleMain;border-top: solid #490c06 1px;border-right: solid #490c06 1px;border-left: solid #490c06 1px;
    border-top-left-radius: 15px; font-weight: bold; font-size: 110%; color: white;
    border-top-right-radius: 15px; padding: 0.5%; background-color: #b71e10}
  .MainParty { grid-area: MainParty; padding :0.5%;
    border: solid #490c06 1px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fdfaf9;
  display: grid; 
    grid-template-columns: 1fr 1.8fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "PicWel1 WelText PicWel2"; }

      
     .PicWel1 { grid-area: PicWel1; }
      .WelText { grid-area: WelText; padding: 1%;}
      .PicWel2 { grid-area: PicWel2;}
      .ConUni2 { 
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 0.01fr 0.8fr; 
        gap: 0px 0px; 
        grid-template-areas: 
          "titleMain2 titleMain2 titleMain2" 
          "MainParty2 MainParty2 MainParty2"; 
        margin: 1%;
        grid-area: ConUni;
      }
        .titleMain2 { grid-area: titleMain;border-top: solid #490c06 1px;border-right: solid #490c06 1px;border-left: solid #490c06 1px;
          border-top-left-radius: 15px; font-weight: bold; font-size: 110%; color: white;
          border-top-right-radius: 15px; padding: 0.5%; background-color: #b71e10;}
        .MainParty2 { grid-area: MainParty; 
          border: solid #490c06 1px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #fdfaf9;
      }     
      .footerPageCon{
        grid-area: footerPageCon;
          border-bottom: solid #490c06 1px;
          border-left: solid #490c06 1px;
          border-right: solid #490c06 1px;
          border-Bottom-left-radius: 15px; font-weight: bold; font-size: 110%; color: white;
          border-Bottom-right-radius: 15px; padding: 0.5%; background-color: #b71e10;}

          .ConUni3 { 
            display: grid; 
            grid-template-columns: 1fr 1fr 1fr; 
            grid-template-rows: 0.01fr 0.8fr 0.1fr; 
            gap: 0px 0px; 
            grid-template-areas: 
              "titleMain titleMain titleMain" 
              "MainParty3 MainParty3 MainParty3"
              "footerPageCon footerPageCon footerPageCon";
            margin: 1%;
            grid-area: ConUni3;
          }
          .MainParty3 { grid-area: MainParty3; 
            border: solid #490c06 1px;
          background-color: #fdfaf9;
        }     

        .ConUniPart{ 
          display: grid; 
          grid-template-columns: 1fr 1fr; 
          grid-template-rows: 1fr; 
          gap: 0px 0px; 
          grid-template-areas: 
            "textPartUni  PicPartUni"; 
          grid-area: ConUniPart;
        }
        .textPartUni{
          grid-area: textPartUni;
        }
        .PicPartUni{
          grid-area: PicPartUni;
        }

        

        .accordion {
          --bs-accordion-color: #ffffff !important;
          --bs-accordion-bg: #495057 !important;
          --bs-accordion-transition: color 0.15s ease-in-out,var(--bs-accordion-bg) 0.15s ease-in-out,var(--bs-accordion-bg) 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease !important;
          --bs-accordion-border-color: #78705d !important;
          --bs-accordion-btn-color: #ffffff !important;
          --bs-accordion-btn-bg: var(--bs-accordion-bg);
          --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e) !important;
          --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e) !important;
          --bs-accordion-btn-focus-border-color: #78705d !important;
          --bs-accordion-btn-focus-box-shadow: 0 0 0 0.1rem rgba(220, 226, 236, 0.25) !important;
          --bs-accordion-active-color: #ffffff !important;
          --bs-accordion-active-bg: #343a40 !important;
      }

      .pdfStyle{
       
      }
.react-pdf__Outline{
        
      }
      .react-pdf__Page{
       
      }
     .react-pdf__Document{
      
      
     }
      
     .pdf-container{
      margin-top: 1%;
      margin-bottom: 1%;
      display: flex !important;
      justify-content: center !important;
      place-items: center !important;
      
    }
 
    .leaflet-popup{
      bottom: -35px !important; 
    }

    .imageSt{
      object-fit: cover;
    }